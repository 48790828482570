<template>
    <div class="supplier">
        <el-page-header @back="$router.go(-1)" content="企业信息"></el-page-header>
        <div class="heard f-c-c"  :style="{ backgroundImage: `url(${backgroundImageUrl})` }">
            <span>{{ enterpriseName }}</span>
            <img v-if="memberIcon" class="h-30 ml-20" :src="memberIcon" alt="memberIcon">
        </div>
        <div class="info f-w">
            <div class="info_item" v-for="(item, index) of enterpriseList" :key="index">
                <div class="title">{{ item.title }}：</div>
                <div class="value">{{ item.value }}</div>
            </div>
            <div class="info_introduction">
                <span>企业简介:</span>
                <div class="">{{ introduction }}</div>
            </div>
            <vue-qr v-if="isqcCodeUrl" class="qrcode" :size="120" :logoScale="0.2" :logoSrc="imageSrc"
                :text="qcCodeUrl"></vue-qr>
        </div>
        <!-- 按钮 -->
        <div class="supplier-btns f-c-a">
            <router-link v-if="isShowEdit" :to="{
            name: 'EditEnterpriseInfo', query: {
                key: $UrlEncode.encode(JSON.stringify({
                    id: queryId, type: 'modify'
                }))
            }
        }">
                <el-button class="sign-out-supply edit"><i class="el-icon-edit-outline"></i>编辑</el-button>
            </router-link>
            <template v-if="queryType === 'supply'">
                <el-button class="sign-out-supply other" @click="handleBtn(enterpriseState)">{{ enterpriseState_text }}
                </el-button>
            </template>
        </div>
        <!-- 相关商品 -->
        <div class="relatedgoods f-fr-jc" v-if="queryType === 'product'">
            <div class="title">相关商品</div>
            <div class="search f-c">
                <div class="search-options">
                    <!-- typeNameChange -->
                    <span @click="typeNameChange('name')"
                        :class="typeName == 'name' ? 'pitchOn' : 'NopitchOn'">搜名称</span>
                    <span style="margin:0 30px;" @click="typeNameChange('num')"
                        :class="typeName == 'num' ? 'pitchOn' : 'NopitchOn'">搜编号</span>
                </div>
                <el-select class="search_select" v-model="productType" placeholder="请选择" @change="productTypeChange()">
                    <el-option v-for="item in options" :key="item.value" :label="item.label"
                        :value="item.value"></el-option>
                </el-select>
                <input type="text" placeholder="请输入要搜索的内容" v-model.trim="searchValue" class="search_input" />
                <el-button class="search-icon" @click="goToColourSearch"><img style="height: 12px;width: 12px;"
                        src="@/assets/colorSearchIcon.png" alt=""></el-button>
                <el-button class="search-icon1" @click="handleImageUploadBox"><i class="el-icon-camera"></i></el-button>
                <el-button class="search-icon2" style="color: #FFFFFF;background: #0a78f3;" icon="el-icon-search"
                    @click="searchBtn"></el-button>
                <!-- 图片搜索 -->
                <ImageUpload @closeUploadBox="closeUploadBox" v-show="showImageUploadBox"
                    :imageUploadTrends="imageUploadTrends" :ImageSearchtype="'EnterpriseRelatedProducts'"
                    :style="ImageUploadStyle" :key="new Date().getTime()">
                </ImageUpload>
            </div>
            <div class="supplier_box f-w" v-if="tabContainerList && tabContainerList.length != 0">
                <div class="supplier_item" v-for="(item, index) in tabContainerList" :key="index"
                    @click="toDetailPage(item.productId)"
                    :style="{ marginRight: ((index + 1) % 5 != 0) ? '50px' : '0' }">
                    <img :src="item.img1Url" alt="">
                    <div class="name">{{ item.productName }}</div>
                    <div class="f-c-b text">
                        <span class="price">{{ item.unitPrice ? item.valuationCurrency : '' }}
                            {{ item.unitPrice ? item.unitPrice.toFixed(2) : '暂无报价' }}</span>
                        <span class="characters">{{ item.shortageFlag == 'Y' ? '售罄' : '在售'
                            }}</span>
                    </div>
                    <!-- 角标 -->
                    <div class="cornermark">{{ item.productTypeStr }}</div>
                </div>
                <Pagination :total="total" :pageNum.sync="currentPage" :limit.sync="size" :layout="layout"
                    @pagination="fetchData" />
            </div>
            <div v-if="iSempty" class="f-fr-jc empty">
                <!-- <img src="@/assets/loading.png" alt=""> -->
                <span>未搜索到相关商品</span>
            </div>
        </div>

    </div>
</template>
<script>
import { UrlDecodeIdType } from '@/mixins/UrlDecodeMixin';
import { PaginationRetention } from '@/mixins/PaginationRetentionMixin';
import { UnitAccount } from "@/utils/attributeValue1Options"
import VueQr from 'vue-qr';
export default {
    name: 'SupplierInfo',
    mixins: [UrlDecodeIdType, PaginationRetention],
    components: {
        ImageUpload: () => import(/* webpackChunkName: "ImageUpload" */ '@/components/ImageUpload.vue'),
        VueQr
    },
    data() {
        return {
            enterPriseImage: '',
            enterpriseName: '',
            enterpriseId: '',
            introduction: '',
            enterpriseList: [],
            tabContainerList: [],
            enterpriseState: '',//企业状态
            enterpriseState_text: '',//企业状态文本
            applyId: 0,
            isShowEdit: false,//是否展示编辑入口
            confirmTitle: '',//确认框标题
            searchValue: '',
            productType: this.$getStorage('productType') ?? 0,
            options: [],
            imageUploadTrends: {
                'position': 'absolute',
                'right': '30px',
                'top': '50px'
            },
            ImageUploadStyle: {
                'width': '80%',
                'left': 0,
                'text-align': 'center',
            },
            showImageUploadBox: false,//展示图片上传盒子
            iSempty: false,
            typeName: 'name',
            qcCodeUrl: '',
            imageSrc: require("@/assets/supply/erweima_logo.png"),
            isqcCodeUrl: false,
            enterpriseLevel:'',
            backgroundImageUrl:'',
            memberIcon:'',
        }
    },
    created() {
        this.findByIdEnterpriseInfo(this.queryId);
        this.$setStorage('EnterpriseRelatedId', this.queryId)
        if (this.queryType === 'supply') {//点击企业
            let enterpriseInfo = this.$getStorage('enterpriseStateInfo'),
                { state, dcPermissionFlag } = enterpriseInfo,
                { e_i } = enterpriseInfo.userPermission,
                enterpriseState = '',
                enterpriseState_text = '',
                confirmTitle = '';
            if (state === '020' && e_i === 'W') {
                enterpriseState = 1
                enterpriseState_text = '退出企业'
                confirmTitle = '确定退出该企业？'
                if (dcPermissionFlag === 'Y') {
                    enterpriseState = 2
                    enterpriseState_text = '解散企业'
                    confirmTitle = '确定解散企业？'
                }
            } else if (state === '000') {
                enterpriseState = 3
                enterpriseState_text = '申请加入'
                confirmTitle = '确定加入该企业吗？'
            } else if (state === '041' && dcPermissionFlag === 'Y') {
                enterpriseState = 4
                enterpriseState_text = '撤销解散'
                confirmTitle = '确定撤销解散申请吗？'
            } else if ((state === '020' || state === '031' || state === '041' || state === '042') && dcPermissionFlag === 'N') {
                enterpriseState = 5
                enterpriseState_text = '退出企业'
                confirmTitle = '确定退出该企业？'
            } else if (state === '061') {
                enterpriseState = 6
                enterpriseState_text = '撤销退出'
                confirmTitle = '确定撤销退出？'
            } else if (state === '042' && dcPermissionFlag === 'Y') {
                enterpriseState = 7
                enterpriseState_text = '恢复企业状态'
                confirmTitle = '确定恢复？'
            }
            if (e_i === 'W') {
                if (state === '041' || state === '042') {
                    this.isShowEdit = false
                } else {
                    this.isShowEdit = true
                }
            }
            this.enterpriseState = enterpriseState;
            this.enterpriseState_text = enterpriseState_text
            this.applyId = enterpriseInfo.auditNumber
            this.confirmTitle = confirmTitle;
            // this.enterpriseInfo = enterpriseInfo;
            
        }
        this.findSearchProductTypeList();
        this.getEnterpriseQcCodeInfo();
    },
    watch: {
        searchValue() {
            if (!this.searchValue) {
                this.getDate()
            }
        }
    },
    methods: {
        getDate() {
            this.openEnterpriseProductList(this.paging, this.pagingSize);
        },
        // 获取产品类型
        async findSearchProductTypeList() {
            let data = {
                page: 1,
                size: 10
            }
            await this.$http.findSearchProductTypeList(data).then(res => {
                if (res.code == 200) {
                    let records = res.data.records,
                        odj = {};
                    records.forEach(item => {
                        odj = {
                            value: item.productTypeId,
                            label: item.productTypeName
                        }
                        this.options.push(odj)
                    });
                }
            })
        },
        // 根据企业id获取企业列表详情接口 
        findByIdEnterpriseInfo(enterpriseId) {
            let that = this, data = {
                enterpriseId: enterpriseId ? enterpriseId : this.$getStorage('enterpriseStateInfo').enterpriseId,
                source: "pc",
                applicantId: parseInt(that.$store.state.userId),
                token: that.$getStorage("token")
            };
            that.$http.findByIdEnterpriseInfo(data).then(res => {
                let { enterpriseName, contact, enterpriseTelephone, contactTelephone, city, address, eimage1, introduction, legalPerson, enterpriseId, enterpriseLevel } = res.data;
                that.enterpriseName = enterpriseName;
                that.enterpriseId = enterpriseId;
                that.introduction = introduction;
                that.enterPriseImage = eimage1;
                that.enterpriseList = [
                    {
                        "title": "法人姓名",
                        "value": legalPerson ?? ''
                    }, {
                        "title": "联系人",
                        "value": contact ?? ''
                    }, {
                        "title": "企业电话",
                        "value": enterpriseTelephone ?? ''
                    }, {
                        "title": "联系电话",
                        "value": contactTelephone ?? ''
                    }, {
                        "title": "所在城市",
                        "value": city ?? ''
                    }, {
                        "title": "详细地址",
                        "value": address ?? ''
                    }
                ];
                this.enterpriseLevel = enterpriseLevel;
                switch(enterpriseLevel){
                    case 0:
                        this.backgroundImageUrl  = require('../assets/supply/bannerSupplyIndex.png');
                        this.memberIcon = '';
                    break;
                    case 1:
                        this.backgroundImageUrl  = require('../assets/supply/banner1.png');
                        this.memberIcon = require('../assets/silver.png');
                    break;
                    case 2:
                        this.backgroundImageUrl  = require('../assets/supply/banner2.png');
                        this.memberIcon = require('../assets/gold.png');
                    break;
                    case 3:
                        this.backgroundImageUrl  = require('../assets/supply/banner3.png');
                        this.memberIcon = require('../assets/jadeite.png');
                    break;
                    case 4:
                        this.backgroundImageUrl  = require('../assets/supply/banner4.png');
                        this.memberIcon = require('../assets/diamond.png');
                    break;
                }
            });
        },
        // 首页企业开放商品搜索列表接口
        openEnterpriseProductList(page = this.page, size = this.size) {
            let that = this,
                data = {};
            if (this.typeName == 'name') {
                data = {
                    size,
                    page,
                    productType: that.productType,
                    enterpriseId: that.queryId,
                    productName: that.searchValue,
                    lastModifyUserId: parseInt(that.$store.state.userId),
                    token: that.$getStorage("token")
                };
            } else {
                data = {
                    size,
                    page,
                    productType: that.productType,
                    enterpriseId: that.queryId,
                    productNumber: that.searchValue,
                    lastModifyUserId: parseInt(that.$store.state.userId),
                    token: that.$getStorage("token")
                };
            }
            that.$http.openEnterpriseProductList(data).then(res => {
                let { records } = res.data;
                records.forEach(item => {
                    item.productTypeStr = UnitAccount.getproductType(item.productType)
                })
                that.tabContainerList = records;
                that.iSempty = res.data.records.length == 0 ? true : false;
                that.total = res.data.total;
                that.currentPage = res.data.current;
            });
        },
        searchRelevantProduct() {
            this.openEnterpriseProductList()
        },
        searchBtn() {
            if (!this.searchValue) {
                return this.$common.message('请输入搜素内容！', 'warning')
            }
            this.openEnterpriseProductList()
        },
        typeNameChange(value) {
            this.typeName = value;
            this.searchValue = '';
        },
        handleBtn(state) {
            if (state == 3) {//申请加入
                this.userApplyJoinEnterprise()
            } else if (state == 1 || state == 5) {//退出企业
                this.quitSupply()
            } else if (state == 6) {//撤销退出
                this.userRevokeJoinEnterprise()
            } else if (state == 2) {//企业解散
                this.disbandEnterprise()
            } else if (state == 4 || state == 7) {//撤销解散  恢复企业状态
                this.revokeEnterpriseAudit()
            }
        },
        // 用户申请退出企业接口
        quitSupply() {
            let that = this;
            that.$confirm(that.confirmTitle, '提示').then(() => {
                that.$http.userApplyQuitEnterprise({
                    enterpriseId: that.queryId,
                    applyUserId: parseInt(that.$store.state.userId),
                    token: that.$getStorage('token')
                }).then(res => {
                    if (res.code == 200) {
                        that.$common.message(res.msg, 'success')
                        that.$common.DelayedBack(0);
                    }
                })
            }).catch(() => { })
        },
        // 用户撤销加入企业或用户撤销退出企业接口
        userRevokeJoinEnterprise() {
            let that = this;
            that.$confirm(that.confirmTitle, '提示').then(() => {
                that.$http.userRevokeJoinEnterprise({
                    applyUserId: parseInt(that.$store.state.userId),
                    token: that.$getStorage('token'),
                    applyId: that.applyId
                }).then(res => {
                    if (res.code == 200) {
                        that.$common.message(res.msg, 'success')
                        that.$common.DelayedBack(0);
                    }
                })
            }).catch(() => { })
        },
        // 申请加入企业
        userApplyJoinEnterprise() {
            let that = this;
            that.$confirm(that.confirmTitle, '提示').then(() => {
                that.$http.userApplyJoinEnterprise({
                    enterpriseId: that.queryId,
                    applyUserId: parseInt(that.$store.state.userId),
                    token: that.$getStorage('token')
                }).then(res => {
                    if (res.code == 275) {
                        that.$confirm(res.msg, '提示').then(() => {
                            that.revokePreviousThenApply(res.data)
                        }).catch(() => { })
                    } else {
                        that.$common.message(res.msg, 'success')
                        that.$common.DelayedBack(2000);
                    }

                })
            }).catch(() => { })
        },
        // 撤销上一条申请然后再加入企业
        revokePreviousThenApply(applyId) {
            let that = this;
            that.$http.userRevokeJoinEnterprise({
                applyUserId: parseInt(that.$store.state.userId),
                token: that.$getStorage('token'),
                applyId
            }).then(res => {
                if (res.code == 200) {
                    that.$http.userApplyJoinEnterprise({
                        enterpriseId: that.queryId,
                        applyUserId: parseInt(that.$store.state.userId),
                        token: that.$getStorage('token')
                    }).then(res => {
                        if (res.code == 200) {
                            that.$common.message(res.msg, 'success')
                            that.$common.DelayedBack(2000);
                        }
                    })
                }
            })
        },
        // 企业解散接口
        disbandEnterprise() {
            let that = this;
            that.$confirm(that.confirmTitle, '提示').then(() => {
                that.$http.disbandEnterprise({
                    applicantId: parseInt(that.$store.state.userId),
                    token: that.$getStorage('token')
                }).then(res => {
                    if (res.code == 200) {
                        that.$common.message(res.msg, 'success')
                        that.$common.DelayedBack(2000);
                    }
                })
            }).catch(() => { })

        },
        // 用户撤销企业审核接口
        revokeEnterpriseAudit() {
            let that = this;
            that.$confirm(that.confirmTitle, '提示').then(() => {
                that.$http.revokeEnterpriseAudit({
                    applicantId: parseInt(that.$store.state.userId),
                    token: that.$getStorage('token')
                }).then(res => {
                    if (res.code == 200) {
                        that.$common.message(res.msg, 'success')
                        that.$common.DelayedBack(1500);
                    }
                })
            }).catch(() => { })

        },
        // 跳转去商品详情
        toDetailPage(productId) {
            if (!this.$getStorage('token')) {
                // this.$emit('showLoginBox')
                this.loginBoxVisible = true
            } else {
                this.$router.push({
                    name: 'ProductDetail',
                    query: {
                        key: this.$UrlEncode.encode(JSON.stringify({
                            id: productId,
                            isshowsupply: false,
                        }))
                    }
                })
            }
        },
        // 展开图片上传盒子
        handleImageUploadBox() {
            this.showHistoryBox = false;
            this.showImageUploadBox = true;
        },
        // 关闭上传图片盒子
        closeUploadBox(value) {
            this.showImageUploadBox = value
        },
        // 
        productTypeChange() {
            this.$setStorage('productType', this.productType);
            this.$setStorage('isreload', true);
            this.openEnterpriseProductList();
        },
        // 跳转去色彩库
        goToColourSearch() {
            this.$removeStorage('params');
            this.$removeStorage('csState');
            this.$removeStorage('picker_color');
            this.$removeStorage('picker_colorName');
            this.$router.push({
                name: 'Coloursearch',
                query: {
                    key: this.$UrlEncode.encode(JSON.stringify({
                        enterpriseId: this.enterpriseId,
                    }))
                }
            })
        },
        // 获取二维码
        getEnterpriseQcCodeInfo() {
            let that = this;
            that.$http.getEnterpriseQcCodeInfo({
                qcType: 'cq_enterprise'
            }).then(res => {
                let { qcCodeUrl } = res.data;
                this.qcCodeUrl = qcCodeUrl + `?enterpriseId=${this.queryId}`;
                this.isqcCodeUrl = true;
            })
        },
    },
    beforeDestroy() {
        this.$removeStorage('fetchDataPage')
        this.$removeStorage('fetchDataSize')
    }
}
</script>

<style lang="less" scoped>
.supplier {
    width: 1200px;
    margin: auto;

    .heard {
        // margin-bottom: 50px;
        padding: 0 20px;
        height: 100px;
        // background: no-repeat;
        background-size: cover;


        span {
            font-weight: 500;
            font-size: 30px;
            color: #FFFFFF;
        }
    }

    .info {
            padding: 30px 0px;
        // background: #F5F8F9;
        position: relative;

        .info_item {
            // margin-right: 1%;
            margin-bottom: 15px;
            width: 45%;
            height: 45px;
            // background: #ffffff;
            padding: 15px 20px;

            .title {
                color: #999999;
                font-size: 14px;
            }

            .value {
                margin-top: 15px;
                color: #333333;
                font-size: 16px;
                font-weight: 400;
            }
        }

        .info_introduction {
            width: 100%;
            margin-top: 40px;
            background: #FFFFFF;
            padding: 15px 20px;

            span {
                color: #999999;
                font-size: 14px;

            }

            div {
                font-size: 16px;
                font-weight: 400;
                color: #333333;
                line-height: 32px;
            }
        }

        .qrcode {
            position: absolute;
            right: 0px;
            top: 20px;
        }
    }

    .supplier-info-box {
        text-align: center;
        margin: 50px auto 80px;

        .supplier-img {
            width: 30%;
            margin: 40px;
        }

        .supplier-info {
            width: 70%;
            margin: auto;
            flex-wrap: wrap;
            border: 1px solid #999;

            .supplier-item:not(:last-child) {
                border-bottom: 1px solid #999;
            }

            .supplier-item {
                box-sizing: border-box;
                width: 100%;
                line-height: 40px;

                span:first-child {
                    width: 25%;
                }

                span:last-child {
                    text-align: left;
                    padding-left: 20px;
                    border-left: 1px solid #999;
                }
            }

        }
    }

    .supplier-btns {
        width: 70%;
        margin: auto;

        .supplier-relevant {
            font-size: 18px;
            line-height: 40px;
            background: #edebeb;
            // margin: 50px auto 10px;
            // padding-left: 20px;
            color: #000;

        }
    }

    .sign-out-supply {
        width: 160px;
        height: 60px;
        padding: 10px;
        text-align: center;

        font-size: 16px;
        border-radius: 10px;
        margin: 30px 0;

    }

    .edit {
        background: linear-gradient(106deg, #026EF4 0%, #38A8F8 100%);
        color: #ffffff;
    }

    .other {
        background-color: #EFF4FA;
        color: #0363FA;
    }

    .sign-out-supply:hover {
        cursor: pointer;
    }

    .search-input {
        margin: 20px 0 10px;

        button {
            position: absolute;
            right: 0;
            width: 10%;
        }
    }

    .relatedgoods {
        margin-top: 30px;

        .title {
            font-size: 24px;
            font-weight: 500;
            color: #333333;
            line-height: 60px;
            text-align: center;
            margin-bottom: 25px;
        }

        .search {
            position: relative;

            .search-options {
                position: absolute;
                top: -30px;
                left: 130px;

                .pitchOn {
                    border-bottom: 3px solid #0270F2;
                    padding: 5px;
                    color: #333333;
                    font-size: 14px;
                    cursor: pointer;
                }

                .NopitchOn {
                    color: #999999;
                    font-size: 14px;
                    padding: 5px;
                    cursor: pointer;
                }
            }

            .search_select {
                width: 110px;
                position: absolute;
                left: 0;
            }

            .search-icon {
                border-radius: 0;
                position: absolute;
                right: 110px;
                border: none;
            }

            .search-icon1 {
                border-radius: 0;
                position: absolute;
                right: 55px;
                border: none;
            }

            .search-icon2 {
                position: absolute;
                right: 0;
                border-radius: 0 4px 4px 0;
            }

            input {
                background-color: #FFF;
                border-radius: 4px 0 0 4px;
                border: 1px solid #E0E1E3;
                border-right: none !important;
                box-sizing: border-box;
                color: #606266;
                display: inline-block;
                height: 40px;
                line-height: 40px;
                outline: 0;
                padding: 0 50px 0 110px;
                transition: border-color .2s cubic-bezier(.645, .045, .355, 1);
                width: 500px;
            }

            :deep(.el-input__inner) {
                border: 1px solid #E0E1E3;
                border-right: none !important;
                border-radius: 4px 0 0 4px;
            }

        }

        .supplier_box {
            width: 99%;
            margin-top: 40px;

            .supplier_item {
                background: #F6F8FB;
                border-radius: 10px;
                // margin-right: 40px;
                margin-bottom: 20px;
                cursor: pointer;
                position: relative;

                &:hover {
                    transform: translateY(-3px);
                }

                img {
                    width: 205px;
                    height: 186px;
                    border-radius: 10px 10px 0 0;
                }

                .name {
                    width: 205px;
                    margin: 10px 0;
                    font-size: 16px;
                    padding-left: 5px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    // width: 100%;
                }

                .text {
                    padding: 0 5px 10px 5px;

                    .price {
                        color: #FF4E36;
                        font-size: 14px;
                    }

                    .characters {
                        color: #999999;
                        font-size: 14px;
                    }
                }

                .cornermark {
                    position: absolute;
                    right: 10px;
                    top: 5px;
                    border-radius: 5px;
                    background: #ffffff;
                    text-align: center;
                    height: 14px;
                    line-height: 14px;
                    font-size: 14px;
                    color: #699EF2;
                    padding: 5px;
                }
            }



        }

        .empty {
            padding: 50px 0;

            span {
                margin-top: 20px;
            }
        }
    }
}
</style>